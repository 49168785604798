import React from "react";

const About = () => {
  return (
    <div>
      <div className="head">
        <h3>A propos</h3>
      </div>
      <div></div>

     
    </div>
  );
};

export default About;
